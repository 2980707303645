<template>

  <el-container class="container">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      入库单详情
    </el-header>
    <el-container class="container-box">
      <el-container class="container1 left-container">
        <el-main class="main left-main">
          <div class="user">
            <div class="name">
              <img src="@/assets/images/avatar.png" alt="">
              <div>
                赵小美
              </div>
            </div>
            <div class="type">
              <span></span>已生成
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              入库编号
            </div>
            <div class="cell-r">
              ZD202102016600
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              入库仓库
            </div>
            <div class="cell-r">
              深圳仓库
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              入库类型
            </div>
            <div class="cell-r">
              收货入库
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              商品数量
            </div>
            <div class="cell-r">
              5件
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              创建时间
            </div>
            <div class="cell-r">
              2021/01/31 12:00
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              备注
            </div>
            <div class="cell-r">
              无
            </div>
          </div>
        </el-main>
      </el-container>
      <el-container class="container1 right-container">
        <el-header>
          <div class="main-title">
            商品信息
          </div>
        </el-header>
        <el-main class="main right-main">
          <div class="body">
            <div class="card">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">菲灵魔法定型发胶</div>
                </div>
                <div class="size">500ml</div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">入库数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    入库单价：<span class="text">¥99</span>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">入库数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    入库单价：<span class="text">¥99</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">菲灵魔法定型发胶</div>
                </div>
                <div class="size">500ml</div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">入库数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    入库单价：<span class="text">¥99</span>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">入库数量：
                    <div class="stepper">
                      10箱
                    </div>
                  </div>
                  <div class="right">
                    入库单价：<span class="text">¥99</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    computed: {},
    watch: {},

    mounted() {},
    methods: {
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },

    }
  };

</script>
<style lang="less" scoped>
  .container {
    height: calc(100vh);
    background-color: #f9f9f9;

    // 头部
    .el-header {
      position: relative;
      z-index: 1;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;

      .left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #46a6ff;
        font-size: 24px;
      }
    }

    .container-box {
      padding: 20px;
      height: calc(100vh - 50px);

      .container1 {
        background-color: #fff;
        color: #666;
        width: 50%;
        padding: 10px 20px;
        box-sizing: border-box;

      }

      .left-container {
        border: 1px solid #f2f2f2;
        border-right: none;
        padding-top: 40px;

        .left-main {
          .user {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-weight: bold;

              img {
                width: 35px;
                margin-right: 10px;
              }
            }

            .type {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;
              color: #999;

              span {
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                background-color: #51d351;
                margin-right: 5px;
              }
            }
          }

          .cell {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 50px;
            border-bottom: 1px solid #f2f2f2;

            &:last-child {
              border-bottom: none;
            }

            .cell-l {
              color: #999;
            }

            .cell-r {
              font-size: 12px;
            }
          }
        }
      }

      .right-container {
        border: 1px solid #f2f2f2;
        border-left: none;

        .main-title {
          font-weight: bold;
          font-size: 14px;
          line-height: 50px;
          border-bottom: 1px solid #f2f2f2;
          display: flex;
          color: #666;
          justify-content: space-between;
        }

        /deep/ .el-header {
          border-bottom: 0;
          padding: 0;

          .main-title {
            border-bottom: 0;
          }
        }

        .card {
          padding: 15px;
          background-color: #f9f9f9;
          border: 1px solid #f2f2f2;
          border-radius: 10px;
          padding-right: 20px;
          margin-bottom: 15px;

          .card-title {
            border-bottom: 1px solid #f2f2f2;

            .name-close {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 0;

              .close {
                font-size: 18px;
                color: #999;
              }
            }

            .size {
              margin-bottom: 10px;
              color: #999;
              font-size: 12px;

            }
          }

          .card-item {
            border-bottom: 1px solid #f2f2f2;
            padding: 10px 0;
            color: #999;

            &:last-child {
              border-bottom: none;
            }

            .text {
              color: #666;
            }

            .top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;

              .right {
                font-size: 18px;
                color: #999;

                span {
                  margin-left: 10px;
                }
              }
            }

            .center {
              margin-bottom: 10px;
            }

            .bottom {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;

              .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;

              }
            }
          }
        }
      }
    }


  }

  .el-main {
    padding: 0 !important;
  }

</style>
